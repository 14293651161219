export default function (tracker) {
    const config = useRuntimeConfig()
    if (config.public.env !== 'production') {
        return
    }
    /*
    list of events:
    page                            PageView
                   
    zip-code.check.success          FindLocation  
    
    order.start                     InitiateCheckout
    order.add-to-cart               AddToCart
    order.finished                  Purchase

    user.registered                 CompleteRegistration
    */
    const id = '507634680337082'

    tracker.add({
        name: 'facebook',
        level: 'tracking',
        settings: {
            code: `!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');`,
            noScript: `<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"
          />`,
        },
        callback() {
            fbq('init', id)

            tracker.listen('page', (_event, _data) => {
                fbq('track', 'PageView')
            })

            tracker.listen('zip-code.check.success', (_event, _data) => {
                fbq('track', 'FindLocation', {})
            })

            tracker.listen('order.start', (_event, _data) => {
                fbq('track', 'InitiateCheckout')
            })

            tracker.listen('order.add-to-cart', (_event, _data) => {
                fbq('track', 'AddToCart')
            })

            tracker.listen('order.finished', (_event, data) => {
                fbq('track', 'Purchase', { value: data.total, currency: 'EUR' })
            })

            tracker.listen('user.registered', (_event, _data) => {
                fbq('track', 'CompleteRegistration')
            })
        },
    })
}
